import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import "./AboutMe.css";
import sonyImage from "../../assets/sony-office.png";

function AboutMe() {
  return (
    <div className="about-me-wrapper">
      <div className="about-me-container">
        <div className="about-me-description">
          <h2 className="about-me-title">Hey, moi c'est Arthur !</h2>
          <p className="about-me-content">
            Passionné d'informatique, j'ai commencé à coder il y a maintenant 10
            ans. J'ai eu la chance de travailler comme ingénieur logiciel pour
            des institutions financières (PwC), des startups ou encore pour le
            géant de la technologie SONY - PlayStation.
            <br />
            <br /> En fondant DevArt Studio je souhaite démocratiser l'accès à
            la présence en ligne à tous types de structures qui n'ont pas les
            compétences, le temps ou encore le budget de se créer un site
            internet.
          </p>
          <h2>Envie de me connaître encore mieux ? </h2>
          <div className="social-links">
            <a
              href="YOUR_LINKEDIN_URL"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <FontAwesomeIcon icon={faArrowRight} color="yellow" size="xl" />
            </a>
            <a
              href="https://www.linkedin.com/in/arthur-gamby"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCxrT9QLqHx5fWeSaL8MoJLQ"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <FontAwesomeIcon icon={faYoutube} className="social-icon" />
            </a>
          </div>
        </div>
        <div className="about-me-image-container">
          <img src={sonyImage} alt="sony office" />
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
