import "./App.css";
import { AboutMe } from "./components/AboutMe";
import { Contact } from "./components/Contact";
import Services from "./components/Services/Services";
import { Welcome } from "./components/Welcome";

function App() {
  return (
    <div className="App">
      <Welcome />
      <AboutMe />
      <Services />
      <div id="contact-section">
        <Contact />
      </div>
    </div>
  );
}

export default App;
