import "./Services.css";
import Computer from "../../assets/computer.png";

function Services() {
  return (
    <div className="services-wrapper">
      <div className="services-container">
        <div className="services-column">
          <div className="services-inner-container">
            <h1 className="services-title">
              Un développeur rien que pour vous
            </h1>
            <p className="services-description">
              Travailler avec moi c'est : <br />
              Pas de jargon compliqué.
              <br /> Une communication simple et rassurante.
              <br /> Une transparence et une proximité que vous ne trouverez pas
              dans les grandes sociétés web !
            </p>
            <img src={Computer} alt="computer" className="services-image" />
          </div>
        </div>
        <div className="services-column">
          <div className="services-inner-container">
            <div className="services-box">
              <h1>Design et Création du Site Web</h1>
              <p className="services-description">
                Je m'occupe de tout, du design à la conception. Un site qui vous
                ressemble, accessible sur ordinateur comme sur téléphone.
              </p>
            </div>
            <div className="services-box">
              <h1>Nom de domaine et Hébergement</h1>
              <p className="services-description">
                Pas de panique, je m'occupe de tout : de l'achat du nom de
                domaine à l'hébergement de votre site.
              </p>
            </div>
            <div className="services-box">
              <h1>Maintenance et mises à jour</h1>
              <p className="services-description">
                Votre site web sera toujours au top avec une maintenance
                régulière et des mises à jour incluses.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
