import React from "react";
import "./Welcome.css";

function Welcome() {
  const handleScroll = () => {
    const contactSection = document.getElementById("contact-section");
    window.scrollTo({
      top: contactSection.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <div className="welcome-container-wrapper">
      <div className="container">
        <h4 className="title">ARTHUR GAMBY - WEB DEVELOPER</h4>
        <h1 className="quote">Je crée votre site internet à prix d'ami.</h1>
        <button className="contact-button" onClick={handleScroll}>
          On discute ?
        </button>
      </div>
    </div>
  );
}

export default Welcome;
