import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import "./Contact.css";

function Contact() {
  const [state, handleSubmit] = useForm("xqkvkabw");

  if (state.succeeded) {
    return (
      <div className="contact-wrapper">
        <h1 className="contact-header-success">
          Merci pour votre message, je vous contacte très vite !
        </h1>
      </div>
    );
  }

  return (
    <div className="contact-wrapper">
      <h1 className="contact-header">On discute ?</h1>
      <form onSubmit={handleSubmit} className="contact-form">
        <label htmlFor="email" className="contact-form-label">
          Email
          <input
            id="email"
            type="email"
            name="email"
            required
            className="contact-form-input"
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </label>
        <label htmlFor="message" className="contact-form-label">
          Message
          <textarea
            id="message"
            name="message"
            required
            className="contact-form-textarea"
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
        </label>
        <button
          type="submit"
          disabled={state.submitting}
          className="contact-form-button"
        >
          C'est parti !
        </button>
      </form>
    </div>
  );
}

export default Contact;
